import {AgGridReact} from 'ag-grid-react'
import {Formik} from 'formik'
import moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import {Button, Dropdown, Form, Grid, Header, Image, Label, Modal} from 'semantic-ui-react'
import swal from 'sweetalert2'
import {AclRender} from '../../components/acl/components'
import {DatePickr} from '../../components/form/DatePickr'
import {FF, FormikInput} from '../../components/form/FormInputs'
import UnitsSelect from '../../components/form/UnitsSelect'
import ActivityLoading from '../../components/Loading'
import PageBase from '../../components/PageBase'
import {WebcamCaptureUploader} from '../../components/WebcamCapture'
import {LOST_STATUS} from '../../fields'
import ToasterService from '../../services/ToasterService'
import {putApi} from '../../utils/api'
import {getLosts} from '../../utils/DataService'
import {dateTimeFormat, removeAccents} from '../../utils/Formatters'
import {gridDefaults} from '../../utils/gridDefaults'

export default class LostListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        },
        rowHeight: 70
      },
      quickFilterText: null,
      current_lost: null,
      loading: false,
      open_modal: false,
      details_modal: false,
      columnDefs: [
        {
          headerName: 'Objeto',
          field: 'name',
          width: 70,
          filter: 'text',
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Local',
          field: 'found_place',
          width: 100,
          filter: 'text',
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Unidade/Status',
          field: 'unit.with_block',
          width: 55,
          filter: 'text',
          cellRendererFramework: (params) => {
            let value = params.getValue()
            let status_value = LOST_STATUS[params.data.status]

            let color_map = {
              1: 'yellow',
              2: 'green',
              3: 'red'
            }

            return (
              <div>
                {value} &nbsp;
                <Label color={color_map[params.data.status]} className="grid-label">{status_value}</Label>
              </div>
            )
          }
        },
        {
          headerName: 'Unidade',
          field: 'unit.with_block',
          width: 40,
          filter: 'text',
          hide: true
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 40,
          filter: 'text',
          valueGetter: (params) => LOST_STATUS[params.data.status],
          hide: true
        },
        {
          headerName: 'Data',
          width: 80,
          cellRendererFramework: (params) => {
            return (
              <div>
                Criado: {dateTimeFormat(params, 'created_at')} <br/>
                Devolvido: {dateTimeFormat(params, 'returned_date')}
              </div>
            )
          }
        },
        {
          headerName: 'Data de Criação',
          field: 'created_at',
          width: 60,
          filter: 'text',
          hide: true,
          valueGetter: (params) => dateTimeFormat(params, 'created_at')
        },
        {
          headerName: 'Devolvido em',
          field: 'returned_date',
          width: 60,
          filter: 'text',
          hide: true,
          valueGetter: (params) => dateTimeFormat(params, 'returned_date')
        },
        {
          headerName: 'Ações',
          width: 115,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellClass: 'ag-button-cell',
          cellRendererFramework: this.actionsFormat
        }
      ],
      getRowNodeId: function (data) {
        return data.id
      }
    }
  }

  onQuickFilterText = (event) => {
    this.setState({ quickFilterText: event })
  }

  actionsFormat = (params) => {
    return (
      <Button.Group basic primary compact>
        <Dropdown
          text="Ações  "
          icon="pencil"
          floating
          button
          className="icon"
        >
          <Dropdown.Menu>
            <Dropdown.Header icon="tags" content="Selecione uma Ação"/>
            <Dropdown.Divider/>

            <Dropdown.Item onClick={() => {
              params.context.componentParent.handleOpenDetails(params.data)
            }}>
              Detalhes
            </Dropdown.Item>

            <AclRender required={['unit.change_lost']}>
              {params.data && params.data.status === 1 &&
                <Dropdown.Item onClick={() => {
                  params.context.componentParent.handleOpen(params.data)
                }}>
                  Retirar
                </Dropdown.Item>}
            </AclRender>

            <AclRender required={['unit.change_lost']}>
              {params.data && params.data.status === 1 &&
                <Dropdown.Item onClick={() => {
                  params.context.componentParent.handleMarkNotFound(params.data)
                }}>
                  Marcar como "Dono não Localizado"
                </Dropdown.Item>}
            </AclRender>
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>
    )
  }

  getData = () => {
    this.setState({ loading: true })

    getLosts().then(data => {
      this.gridApi.setRowData(data)
      this.setState({ loading: false })
      this.gridApi.sizeColumnsToFit()
    }).catch(err => {
      this.setState({ loading: false })
    })
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.getData()
  }

  handleClose = () => {
    this.setState({ open_modal: false, details_modal: false, current_lost: null })
  }

  handleOpen = (data) => {
    this.setState({ open_modal: true, current_lost: data })
  }

  handleMarkNotFound = (data) => {
    this.setState({ loading: true })

    let values = {
      status: 3
    }

    putApi('admin/losts', values, data.id).then((res) => {
      swal({ type: 'success', title: 'Item salvo!' })
      this.setState({ open_modal: false })
      this.getData()
    }).catch((err) => {
      this.setState({ loading: false })
      ToasterService.sendErrors(err)
    })
  }

  handleOpenDetails = (data) => {
    this.setState({ details_modal: true, current_lost: data })
  }

  render() {
    return (
      <PageBase title="Achados e Perdidos" onFilterChange={this.onQuickFilterText} create={
        <AclRender required={['unit.add_lost']}>
          <Link to="/admin/lost-form">
            <button className="ui button primary large">Novo</button>
          </Link>
        </AclRender>}>

        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       gridOptions={this.state.gridOptions}
                       columnDefs={this.state.columnDefs}
                       quickFilterText={this.state.quickFilterText}
                       onGridReady={this.onGridReady.bind(this)}
                       pagination={true}
                       paginationPageSize={25}
                       domLayout={'autoHeight'}
                       getRowNodeId={this.state.getRowNodeId}
          />
        </div>

        <Modal open={this.state.open_modal}>
          <Formik
            ref={node => (this.form = node)}
            initialValues={{
              returned_people: '',
              returned_date: '',
              unit: '',
              status: 2,
              files_attach: []
            }}
            validate={values => {
              let errors = {}
              if (!values.returned_date) errors.returned_date = 'Campo obrigatório'
              if (!values.returned_people) errors.returned_people = 'Campo obrigatório'
              if (!values.unit) errors.unit = 'Campo obrigatório'
              return errors
            }}
            onSubmit={(values) => {
              this.setState({ loading: true })

              putApi('admin/losts', values, this.state.current_lost.id).then((res) => {
                swal({ type: 'success', title: 'Item salvo!' })
                this.setState({ open_modal: false })
                this.getData()
              }).catch((err) => {
                this.setState({ loading: false })
                ToasterService.sendErrors(err)
              })
            }}
            render={({ values, setFieldTouched, setFieldValue, submitForm }) => (
              <>
                <Modal.Header>Retirar</Modal.Header>
                <Modal.Content scrolling>
                  <Form size="large" error>
                    <FF label={'Retirado por'} name="returned_people" component={FormikInput}/>

                    <Form.Group widths="equal">
                      <UnitsSelect name="unit" label={'Selecione a Unidade'}/>

                      <Form.Field>
                        <label>Data que foi retirado</label>
                        <DatePickr
                          name="returned_date"
                          has_time={true}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                        />
                      </Form.Field>
                    </Form.Group>

                    <WebcamCaptureUploader values={values} setFieldValue={setFieldValue} size="medium"/>
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <button className="ui red button" onClick={this.handleClose}>Cancelar</button>
                  <button className="ui button primary" onClick={submitForm}>Enviar</button>
                </Modal.Actions>
              </>
            )}
          />
        </Modal>

        {this.state.details_modal &&
          <Modal open={this.state.details_modal}>
            <Modal.Header>Detalhes</Modal.Header>
            <Modal.Content>
              <Grid columns={'equal'}>
                <Grid.Row>
                  <Grid.Column>
                    <Header sub>Descrição:</Header>
                    <span>{this.state.current_lost?.name}</span>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header sub>Local que foi encontrado:</Header>
                    <span>{this.state.current_lost?.found_place}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <Header sub>Data que foi encontrado:</Header>
                    <span>{moment(this.state.current_lost?.found_date).format('DD/MM/YY HH:mm')}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <Header sub>Status:</Header>
                    <span>{LOST_STATUS[this.state.current_lost?.status]}</span>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header sub>Devolvido em:</Header>
                    <span>{this.state.current_lost?.returned_date &&
                      moment(this.state.current_lost?.returned_date).format('DD/MM/YY HH:mm')}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <Header sub>Retirado por:</Header>
                    <span>{this.state.current_lost?.returned_people}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <Header sub>Unidade:</Header>
                    <span>{this.state.current_lost?.unit?.with_block}</span>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header sub>Data de Criação:</Header>
                    <span>{moment(this.state.current_lost?.created_at).format('DD/MM/YY HH:mm')}</span>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header sub>Fotos:</Header>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  {
                    this.state.current_lost?.files?.map((file) => (
                      <Image size={'medium'} rounded src={file.file} style={{ margin: '8px' }}/>
                    ))
                  }
                </Grid.Row>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <button className="ui red button large" onClick={this.handleClose}>Fechar</button>
            </Modal.Actions>
          </Modal>}

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}
