import { Formik } from 'formik'
import moment from 'moment'
import queryString from 'query-string'
import React from 'react'
import { Button, Checkbox, Form, Grid, Message, Modal } from 'semantic-ui-react'
import swal from 'sweetalert2'
import AccessReasonSelect from '../../components/form/AccessReasonSelect'
import { FF, FormikInput } from '../../components/form/FormInputs'
import UnitsSelect from '../../components/form/UnitsSelect'
import ActivityLoading from '../../components/Loading'
import { AccessDriverTypesEnum } from '../../constants/app-enums'
import { qs } from '../../queries'
import { qs_local_server } from '../../queries-local-server'
import { GuestModel } from '../../typings'
import { getValue, hasValue, show_modal } from '../../utils/Formatters'
import { localPostApi } from '../../utils/LocalApi'
import { GuestHandlerGrid } from './components/GuestHandlerGrid'
import { GuestSearchModal } from './components/GuestSearchModal'
import { QRCodePrinterModal } from './components/QRCodePrinterModal'
import { QuickUnitData } from './components/QuickUnitData'
import { useDispatch } from 'react-redux'
import { actionMap as system_actions } from '../../redux/modules/system'
import { useHistory, useLocation } from 'react-router-dom'
import ToasterService from '../../services/ToasterService'

const styles = {
  box: {
    float: 'left',
    paddingRight: 10
  }, capture: {
    padding: 10,
    backgroundColor: '#ebebeb',
    display: 'flex',
    marginTop: 25
  },
  header: {
    padding: '5px',
    backgroundColor: '#ffffff',
    display: 'flex',
    // margin: '-22px -10px 0px -20px'
  },
  footer: {
    padding: '5px',
    backgroundColor: '#ffffff',
    display: 'flex',
    // margin: 0,
    // position: 'fixed',
    // bottom: 0,
    // right: 0,
    // zIndex: 3,
    // width: 'calc(100vw)'
  } as React.CSSProperties
}

type StateType = {
  loading: boolean,

  selectedGuests: GuestModel[],
  modal_search: boolean,

  current_source: any,
  source_type: any,

  to_pool: boolean,

  access_auth_pins: any[]
}

class ModalForm extends React.Component {
  state: StateType = {
    loading: false,

    selectedGuests: [],
    modal_search: false,

    current_source: null,
    source_type: null,

    to_pool: false,

    access_auth_pins: []
  }
  props: any
  form: any

  componentDidMount() {
    let query = queryString.parse(getValue(this.props, 'location.search'))
    let source = getValue(query, 'source')

    if (this.props.location && this.props.location.state && source) {
      const data = this.props.location.state
      this.handleAccessSource(source, data)
    }

    qs.guests.cacheUpdates()
  }

  handleAccessSource = async (source, data) => {
    if (source && data) {
      this.setState({
        source_type: source,
        current_source: data,
        loading: true,

        selectedGuests: [],
        modal_search: false,

        to_pool: false,

        access_auth_pins: []
      })

      this.form.resetForm()

      if (data.person_id) {
        let guest_data: any[] = await qs.guests.allCached()
        let find_guest = guest_data.find(_ => String(_.id) === String(data.person_id))

        if (find_guest) {
          this.setState({
            selectedGuests: [find_guest]
          })
        }
      }

      if (source === 'accessauthorization') {
        this.form.setFieldValue('access_reason', data.access_reason_id)
        this.form.setFieldValue('destination_unit_id', data.unit_id)
        this.form.setFieldValue('source_id', data.id)
        this.form.setFieldValue('vehicle_plate', data.vehicle_plate)
        this.form.setFieldValue('vehicle_color', data.vehicle_color)
        this.form.setFieldValue('vehicle_model', data.vehicle_model)
      }

      if (source === 'guestlist') {
        this.form.setFieldValue('access_reason', 1)
        this.form.setFieldValue('destination_space_id', data.book.space_id)
        this.form.setFieldValue('source_id', data.id)
      }

      this.setState({
        loading: false
      })

      ToasterService.success('Para concluir, verifique os campos e clique em Registrar Entrada!')

    }
  }

  showGuestSearchModal = () => {
    this.setState({ modal_search: true })
  }

  closeGuestSearchModal = () => {
    this.setState({ modal_search: false })
  }

  updateSelectedGuest = (data) => {
    this.setState({ selectedGuests: data })
  }

  enableToPool = () => {
    this.setState({ to_pool: true })
  }

  render() {
    return (<React.Fragment>
      <Formik
        ref={node => (this.form = node)}
        initialValues={{
          description: '',
          destination_unit_id: '',
          destination_space_id: '',
          source_id: '',
          access_reason: '',
          access_group: '',
          end_date: moment().format('YYYY-MM-DDT[23:59]'),
          status: 3,
          type: 1,
          go_condo: false,
          vehicle_model: '',
          vehicle_plate: '',
          vehicle_color: '',
          error_guests: false
        }}
        validate={values => {
          let errors: any = {}

          if (!values.access_reason) errors.access_reason = true
          if (values.go_condo === false && (!values.destination_unit_id && !values.destination_space_id)) errors.destination_unit_id = true
          if (!values.end_date) errors.end_date = true
          if (this.state.selectedGuests.length < 1) errors.error_guests = true

          console.log(errors)

          return errors
        }}
        onSubmit={async (values) => {
          console.log('form values', values)

          this.setState({ loading: true })

          let base_access_log_item = {
            description: values['description'],
            access_reason: values['access_reason'],
            date: moment().toISOString(),

            end_data: values['end_data'],
            status: values['status'],
            type: values['type'],
            go_condo: values['go_condo'],
            vehicle_model: values['vehicle_model'],
            vehicle_plate: values['vehicle_plate'],
            vehicle_color: values['vehicle_color']
          }

          let source_type = this.state.source_type ? this.state.source_type : 'standard'
          let destination_type = ['standard', 'accessauthorization'].includes(source_type) ? 'unit' : 'space'

          base_access_log_item['destination_unit'] = values['destination_unit_id']
          base_access_log_item['destination_space'] = values['destination_space_id']

          if (source_type === 'guestlist') {
            base_access_log_item['source_guest_list'] = values['source_id']
          }

          if (source_type === 'accessauthorization') {
            base_access_log_item['source_access_authorization'] = values['source_id']
          }

          if (values.go_condo === true) {
            base_access_log_item['destination_unit'] = null
            base_access_log_item['destination_space'] = null

            destination_type = 'team'
          }

          let access_logs: any[] = []

          for (let i of this.state.selectedGuests) {
            let _item = JSON.parse(JSON.stringify(base_access_log_item))
            _item['person'] = i.id
            _item['badge_number'] = i.badge_number

            _item['access_driver_type'] = i.access_driver_type
            _item['access_driver_face'] = i.access_driver_face
            _item['access_driver_serial_hex'] = i.access_driver_serial_hex
            _item['access_driver_serial_dec'] = i.access_driver_serial_dec
            _item['access_driver_wiegand'] = i.access_driver_wiegand
            _item['access_driver_card_company_code'] = i.access_driver_card_company_code

            _item['create_automatic_access_auth'] = i.create_automatic_access_auth
            _item['automatic_access_auth_end_date'] = values.end_date
            access_logs.push(_item)
          }

          let run = true

          if (this.state.to_pool) {
            run = false
            let rules = {
              unit: values['destination_unit_id'], guest_qtd: this.state.selectedGuests.length
            }

            await localPostApi('access-manager/validate_rules', rules).then(res => {
              run = getValue(res, 'success')

              if (hasValue(res, 'message')) {
                swal({ type: 'error', title: res.message })
              }
            }).catch(err => {
              swal({ type: 'error', title: 'Erro ao validar' })
            })

            if (run === false) {
              this.setState({ loading: false })
            }
          }

          if (run) {
            try {
              const response_data = await qs.access_logs.createEntrance(access_logs)

              console.log('response_data', response_data)
              console.log('this.state.selectedGuests', this.state.selectedGuests)

              let guests: any[] = []
              let access_auth_pins: any[] = []

              for (let i of this.state.selectedGuests) {
                let access_log_index = response_data.findIndex(a => hasValue(a, 'person_id') == i.id)

                if (access_log_index !== -1) {
                  let access_log_res = response_data[access_log_index]

                  let datas = {
                    access_log_id: access_log_res.id,
                    access_log: access_log_res,
                    destinationable_id: values['destination_unit_id'] ?? values['destination_space_id'],
                    destinationable_type: destination_type,
                    destinationable: access_log_res['destinationable_name'],
                    access_group: values.access_group,
                    end_date: values.end_date,
                    _access_auth_pin: access_log_res._access_auth_pin,
                    _access_auth_id: access_log_res._access_auth_id
                  }

                  if (access_log_res._access_auth_pin && access_log_res._access_auth_driver_type === AccessDriverTypesEnum.qrcode) {
                    let unit_obj = await qs.units.getCachedDataById(access_log_res.destination_unit_id)
                    console.log('unit_obj', unit_obj)

                    access_auth_pins.push({
                      auth_pin: access_log_res._access_auth_pin,
                      auth_id: access_log_res._access_auth_id,
                      person_name: i.name,
                      unit_with_block: unit_obj?.with_block
                    })
                  }

                  guests.push(Object.assign({}, i, datas))
                }
              }

              console.log('guests_processed', guests)

              await qs_local_server.cache.cache_updates('access_authorizations')

              this.setState({ loading: false })

              localPostApi('access-manager/bulk_enroll_guest', guests)

              let guests_v2: any = []
              for (let o of response_data) {
                if (o._access_auth_driver_type) {
                  guests_v2.push({
                    access_auth_id: o._access_auth_id
                  })
                }
              }

              localPostApi('access-manager_v2/enroll_access_auths', guests_v2)

              // getGuests()

              if (access_auth_pins.length) {
                this.setState({ access_auth_pins: access_auth_pins })
              } else {
                this.props.closeModal()
                show_modal('Operação realizada com sucesso!', 'success')
              }

            } catch (e) {
              this.setState({ loading: false })
              show_modal('Erro ao processar a solicitação!', 'error')
            }
          }
        }}
        render={({ values, errors, submitForm, setFieldValue }) => (<Form className="ui form error" style={{ backgroundColor: '#eef1f5' }}>
          <Modal.Header>
            <div style={styles.header} className="ui form mini">
              <Grid style={{ width: '100%' }}>
                <Grid.Column width={4}>
                  {this.state.source_type !== 'accessauthorization' && this.state.source_type !== 'guestlist' && <React.Fragment>
                    <Checkbox toggle label={<label>Visitando o Condomínio</label>} onClick={(a, b) => {
                      setFieldValue('go_condo', b.checked)
                      setFieldValue('destination_unit_id', '')
                    }}/>

                    {values.go_condo !== true && <UnitsSelect name="destination_unit_id"/>}
                  </React.Fragment>}
                </Grid.Column>

                <QuickUnitData unit_id={values.destination_unit_id}/>

                <Grid.Column width={7}>
                  <Form.Group>
                    <FF label={'Placa Veículo'} name="vehicle_plate" component={FormikInput} width={5}/>
                    <FF label={'Modelo Veículo'} name="vehicle_model" component={FormikInput} width={7}/>
                    <FF label={'Cor Veículo'} name="vehicle_color" component={FormikInput} width={4}/>
                  </Form.Group>
                </Grid.Column>
              </Grid>
            </div>
          </Modal.Header>

          <Modal.Content scrolling style={{ height: '76vh' }}>
            {this.state.source_type && !this.state.current_source?.person_id &&
              <Message
                size="large"
                header={'Essa autorização ainda não possui um visitante vinculado! Por favor selecione um visitante existente' + ' ou cadastre um.'}
                negative/>}

            <div className="ui hidden divider"/>

            <GuestHandlerGrid
              showGuestSearchModal={this.showGuestSearchModal}
              updateSelectedGuest={this.updateSelectedGuest}
              enableToPool={this.enableToPool}
              handleAccessSource={this.handleAccessSource}
              selected_guests={this.state.selectedGuests}
              destination_unit_id={values.destination_unit_id}
              error_guests={errors.error_guests}
            />

            <div className="ui hidden divider" style={{ margin: '100px 0' }}/>

            {this.state.modal_search && <GuestSearchModal
              closeSearchModal={this.closeGuestSearchModal}
              updateSelectedGuest={this.updateSelectedGuest}
              selected_guests={this.state.selectedGuests}
            />}

            {this.state.access_auth_pins.length > 0 && <QRCodePrinterModal qr_codes={this.state.access_auth_pins}/>}
          </Modal.Content>

          <Modal.Actions>
            <div style={styles.footer} className="ui form mini">
              <Grid style={{ width: '100%' }}>
                <Grid.Column width={11}>
                  <Form.Group>
                    <FF label={'Observações da Visita'} name="description" component={FormikInput} width={7}/>
                    <FF label={'Validade da Liberação'} name="end_date" component={FormikInput} width={4} type="datetime-local"/>
                    <AccessReasonSelect width={5}/>
                  </Form.Group>
                </Grid.Column>

                <Grid.Column width={2}>
                  <Button color="red" floated="right" size="large" onClick={this.props.closeModal} style={{ marginTop: '10px' }}>Cancelar</Button>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Button primary floated="right" size="large" onClick={submitForm} style={{ marginTop: '10px' }}>Registrar Entrada</Button>
                </Grid.Column>
              </Grid>
            </div>
          </Modal.Actions>


        </Form>)}
      />

      <ActivityLoading visible={this.state.loading}/>
    </React.Fragment>)
  }
}

const AccessLogFormPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const closeModal = () => {
    dispatch(system_actions.showAccessLogForm(false))
    history.replace(history.location.pathname, {})
    history.replace(history.location.pathname, {})
  }

  return (<Modal size={'fullscreen'} open={true}>
    <ModalForm closeModal={closeModal} location={location}/>
  </Modal>)

}

export default AccessLogFormPage
